import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({ fill }) {
  return (
    <svg width="72px" height="72px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Z" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect fill="#3182CE" x="0" y="0" width="1024" height="1024"></rect>
            <text id="z" font-family="Prata-Regular, Prata" font-size="800" font-weight="normal" line-spacing="800" fill="#FFFFFF">
                <tspan x="305" y="1123">z</tspan>
            </text>
        </g>
    </svg>
  );
}
